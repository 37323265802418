<template>
  <div>
      <b-row class="aling-left">
        <b-col cols="12" sm="6" md="6" class="no-padding">
          <label>Cód. produto:</label>
            <v-autocomplete
              v-if="!CampoObrigatorio"
              solo
              type="number"
              persistent-hint
              :disabled="disabled"
              v-model="data"
              :items="entries"
              :search-input.sync="searchCode"
              hide-no-data
              hide-selected
              item-text="pve_codigo"
              item-value="pve_codigo"
              :label="labelCode"
              :placeholder="placeholder"
              return-object
              @keyup.native.enter="enterItemsCode"
              @keyup.native.tab="enterItemsCode"
            />
             <v-autocomplete
              v-else
              solo
              type="number"
              persistent-hint
              :disabled="disabled"
              v-model="data"
              :items="entries"
              :search-input.sync="searchCode"
              hide-no-data
              hide-selected
              item-text="pve_codigo"
              item-value="pve_codigo"
              :label="labelCode"
              :placeholder="placeholder"
              return-object
              @keyup.native.enter="enterItemsCode"
              @keyup.native.tab="enterItemsCode"
             >
             <template v-slot:prepend-inner>
                  <img
                    style="vertical-align:top;"
                    width="20"
                    height="49"
                    alt=""
                    src="../../assets/faixaCampoObrigatorio.png"
                  />
                </template>
             </v-autocomplete>
        </b-col>
        <b-col cols="12" sm="6" md="6" class="no-padding">
          <label>Cód. referência:</label>
          <v-autocomplete
              v-if="!CampoObrigatorio"
              solo
              persistent-hint
              :disabled="disabled"
              v-model="data"
              :items="entries"
              :search-input.sync="searchCodeRef"
              hide-no-data
              hide-selected
              item-text="pve_codigo_referencia"
              item-value="pve_codigo"
              :label="labelCodeRef"
              :placeholder="placeholder"
              return-object
              @keyup.native.enter="enterItemsCodeRef"
            />
            <v-autocomplete
              v-else
              class="obrigatorio"
              solo
              persistent-hint
              :disabled="disabled"
              v-model="data"
              :items="entries"
              :search-input.sync="searchCodeRef"
              hide-no-data
              hide-selected
              item-text="pve_codigo_referencia"
              item-value="pve_codigo"
              :label="labelCodeRef"
              :placeholder="placeholder"
              return-object
              @keyup.native.enter="enterItemsCodeRef"
            >
                <template v-slot:prepend-inner>
                  <div style="width:20px"></div>
                </template>
            </v-autocomplete>
        </b-col>
      </b-row>
      <b-row class="aling-left">
        <b-col cols="12" sm="3" md="3" class="no-padding">
          <label>Cód. barras:</label>
          <v-autocomplete
              v-if="!CampoObrigatorio"
              solo
              persistent-hint
              :disabled="disabled"
              v-model="data"
              :items="entries"
              :search-input.sync="searchCodeBarras"
              hide-no-data
              hide-selected
              item-text="pve_codigo_barras"
              item-value="pve_codigo"
              :label="labelCodeBarras"
              :placeholder="placeholder"
              return-object
              @keyup.native.enter="enterItemsCodeBarras"
              @keyup.native.tab="enterItemsCodeBarras"
            />
            <v-autocomplete
              v-else
              solo
              persistent-hint
              :disabled="disabled"
              v-model="data"
              :items="entries"
              :search-input.sync="searchCodeBarras"
              hide-no-data
              hide-selected
              item-text="pve_codigo_barras"
              item-value="pve_codigo"
              :label="labelCodeBarras"
              :placeholder="placeholder"
              return-object
              @keyup.native.enter="enterItemsCodeBarras"
              @keyup.native.tab="enterItemsCodeBarras"
            >
              <template v-slot:prepend-inner>
                <div style="width:20px"></div>
              </template>
          </v-autocomplete>
        </b-col>
        <b-col cols="12" sm="9" md="9" class="no-padding">
          <label style="margin-bottom: 24px"></label>
          <v-autocomplete
              v-if="!CampoObrigatorio"
              solo
              :disabled="disabled"
              v-model="data"
              :items="entries"
              :search-input.sync="searchName"
              hide-no-data
              hide-selected
              item-text="product_complete_name"
              item-value="pve_codigo"
              :label="labelName"
              :placeholder="placeholder"
              return-object
              @keyup.native.enter="enterItemsName"
              @keyup.native.tab="enterItemsName"
            />
          <v-autocomplete
              v-else
              solo
              :disabled="disabled"
              v-model="data"
              :items="entries"
              :search-input.sync="searchName"
              hide-no-data
              hide-selected
              item-text="product_complete_name"
              item-value="pve_codigo"
              :label="labelName"
              :placeholder="placeholder"
              return-object
              @keyup.native.enter="enterItemsName"
              @keyup.native.tab="enterItemsName"
            >
                <template v-slot:prepend-inner>
                  <img
                    style="vertical-align:top;"
                    width="20"
                    height="49"
                    alt=""
                    src="../../assets/faixaCampoObrigatorio.png"
                  />
              </template>
            </v-autocomplete>
        </b-col>
      </b-row>
  </div>
</template>

<script>
import apiInstance from "../../services/api/config";

export default {
  name: 'ProductSearchComp',
  props: {
    labelCode: {
      default: '',
    },
    labelCodeRef: {
      default: '',
    },
    labelCodeBarras: {
      default: '',
    },
    labelName: {
      default: '',
    },
    placeholder: {},
    disabled: {
      default: false,
    },
    limpaDados:{
      default: 0,
    },
    CampoObrigatorio:{
      default: false,
    },
  },
  data() {
    return {
      data: '',
      emitData: [],
      firstEntries: [],
      entries: [],
      searchCode: null,
      searchCodeString: null,
      searchCodeRef: null,
      searchCodeBarras: null,
      searchName: null,
      noSearch: false,
    };
  },
  methods: {
    getItemsCode() {
      clearTimeout(this._searchTimerId)
      this._searchTimerId = setTimeout(() => {
        if (this.searchCode != null) {
        apiInstance
            .get('/composite-product/get?pve_codigo=' + this.searchCode)
            .then(res => {
              this.firstEntries = res.data;
              for(var i=0; i<res.data.length; i++){
                this.firstEntries[i].pve_codigo = res.data[i].pve_codigo.toString();
              }
              this.entries = this.firstEntries;
            });
        }
      }, 500)
    },
    enterItemsCode(){
       if (this.searchCode.length >= 2 && this.searchCode != null) {
        apiInstance
            .get('/composite-product/get?pve_codigo=' + this.searchCode)
            .then(res => {
              this.firstEntries = res.data;
              for(var i=0; i<res.data.length; i++){
                this.firstEntries[i].pve_codigo = res.data[i].pve_codigo.toString();
              }
              this.data = this.firstEntries[0];
            });
       }
    },
    getItemsCodeRef() {
      clearTimeout(this._searchTimerId)
      this._searchTimerId = setTimeout(() => {
        if (this.searchCodeRef != null) {
        apiInstance
            .get('/composite-product/get?pve_codigo_referencia=' + this.searchCodeRef)
            .then(res => {
              this.firstEntries = res.data;
              for(var i=0; i<res.data.length; i++){
                this.firstEntries[i].pve_codigo = res.data[i].pve_codigo.toString();
              }
              this.entries = this.firstEntries;
            });
        }
      }, 500)
    },
     enterItemsCodeRef(){
       if (this.searchCodeRef.length >= 2 && this.searchCodeRef != null) {
        apiInstance
              .get('/composite-product/get?pve_codigo_referencia=' + this.searchCodeRef)
              .then(res => {
                this.firstEntries = res.data;
                for(var i=0; i<res.data.length; i++){
                  this.firstEntries[i].pve_codigo = res.data[i].pve_codigo.toString();
                }
                this.data = this.firstEntries[0];
              });
       }
    },
    getItemsCodeBarras() {
      clearTimeout(this._searchTimerId);
      this._searchTimerId = setTimeout(() => {
        if (this.searchCodeBarras != null) {
        apiInstance
            .get('/composite-product/get?pve_codigo_barras=' + this.searchCodeBarras)
            .then(res => {
              this.firstEntries = res.data;
              for(var i=0; i<res.data.length; i++){
                this.firstEntries[i].pve_codigo = res.data[i].pve_codigo.toString();
              }
              this.entries = this.firstEntries;
            });
        }
      }, 500)
    },
    enterItemsCodeBarras(){
      if (this.searchCodeBarras.length >= 2 && this.searchCodeBarras != null) {
       apiInstance
            .get('/composite-product/get?pve_codigo_barras=' + this.searchCodeBarras)
            .then(res => {
              this.firstEntries = res.data;
              for(var i=0; i<res.data.length; i++){
                this.firstEntries[i].pve_codigo = res.data[i].pve_codigo.toString();
              }
              this.data = this.firstEntries[0];
            });
      }
    },
    getItemsName() {
      clearTimeout(this._searchTimerId)
      this._searchTimerId = setTimeout(() => {
        if (this.searchName != null) {
          apiInstance
            .get('/composite-product/get?product_complete_name=' + this.searchName)
            .then(res => {
              this.firstEntries = res.data;
              for(var i=0; i<res.data.length; i++){
                this.firstEntries[i].pve_codigo = res.data[i].pve_codigo.toString();
              }
              this.entries = this.firstEntries;
            });
        }
      }, 500)
    },
    enterItemsName(){
      if (this.searchName.length >= 2 && this.searchName != null) {
       apiInstance
            .get('/composite-product/get?product_complete_name=' + this.searchName)
            .then(res => {
              this.firstEntries = res.data;
              for(var i=0; i<res.data.length; i++){
                this.firstEntries[i].pve_codigo = res.data[i].pve_codigo.toString();
              }
              this.data = this.firstEntries[0];
            });
      }
    },
  },

  computed: {},

  watch: {
    async searchCode() {
      if(this.searchCode != null){
        if (this.searchCode.length >= 2) {
          if(this.searchCode.length == 2){
            this.noSearch = false
          }
          if(this.noSearch == false){
            await this.getItemsCode();
          }
        }
      }
    },
    async searchCodeRef() {
      if(this.searchCodeRef != null){
        if (this.searchCodeRef.length >= 2) {
          if(this.searchCodeRef.length == 2){
            this.noSearch = false
          }
          if(this.noSearch == false){
            await this.getItemsCodeRef();
          }
        }
      }
    },
    async searchCodeBarras() {
      if(this.searchCodeBarras != null){
        if (this.searchCodeBarras.length >= 2) {
          if(this.searchCodeBarras.length == 2){
            this.noSearch = false
          }
          if(this.noSearch == false){
            await this.getItemsCodeBarras();
          }
        }
      }
    },
    async searchName() {
      if(this.searchName != null){
        if (this.searchName.length >= 2) {
          if(this.searchName.length == 2){
            this.noSearch = false
          }
          if(this.noSearch == false){
            await this.getItemsName();
          }
        }
      }
    },
    data() {
      if(this.data != null){
        this.noSearch = true;
        this.data.pve_controla_estoque = 1;
        this.$emit('update:value', this.data);
        console.log('info', this.data)
      }
    },
    limpaDados(){
      this.searchCode = null
      this.searchCodeRef = null
      this.searchCodeBarras = null
      this.searchName = null
      this.entries = []
      this.data = null
    },
  },
};
</script>

<style>
.aling-left{
  text-align: left;
}
.v-list-item__title{
  text-align: left;
}
.obrigatorio{
  padding-left: 20px;
}
</style>