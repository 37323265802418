<template>
  <div id="histModal">
    <b-modal
        :id="id"
        centered
        header-bg-variant="dark"
        header-text-variant="light"
        ok-only
        okVariant="danger"
        ref="modal"
        size="xl"
        :title="'Histórico da composição do produto: ' + item.product_variety.product_mobile.pro_codigo + ' - ' + item.product_complete_name"
    >
        <template>
          <div class="formBordas">
            <v-data-table
              hide-default-footer
              :headers="headers"
              :items="itensEncontrados"
              class="elevation-1"
              :loading="loading"
              loading-text="Carregando..."
            >
            <template v-slot:item="{ item }">
              <!--<tr v-show="item.pch_operacao != 'CADASTRO_ORDEM_PRODUCAO'">-->
              <tr>
                <td>{{item.created_by}}</td>
                <td>{{formataData(item.created_at)}}</td>
                <td>{{formataHora(item.created_at)}}</td>
                <td>{{item.pch_operacao}}</td>
                <td>{{retornaMateriaPrima(item.pch_descricao)}}</td>
              </tr>
            </template>
          </v-data-table>
          </div>
        </template>
    </b-modal>
  </div>
</template>

<script>
import apiInstance from "../../services/api/config";
import ProductListForm from "@/components/list-components/ProductListForm"
import GenericTable from "@/components/tables/GenericTable";
import moment from "moment";

export default {
  name: 'HistModal',
  components: {
    GenericTable,
    ProductListForm
  },
  props: ['id', 'item'],
  data() {
    return {
      loading: true,
      itensEncontrados: [],
      headers: [
        {text: 'Usuário', value: 'created_by', width: '10%'},
        {text: 'Data', value: 'created_at'},
        {text: 'Hora', value: 'horaFormatada', width: '8%'},
        {text: 'Operação', value: 'pch_operacao'},
        {text: 'Descrição', value: 'pch_descricao'},
      ],
    };
  },
  watch:{
    item(){
      this.itensEncontrados = [];
      this.loading = true;
      apiInstance
        .get('/composite-product/' + this.item.pve_codigo + '/historic')
        .then(res => {
          this.itensEncontrados = res.data;
          this.loading = false;
        });
    },
  },
  methods: {
    
    formataData(data){
      return moment(String(data)).format('DD/MM/YYYY')
    },
    formataHora(data){
      return moment(String(data)).format('HH:mm')
    },
    retornaMateriaPrima(descricao){
      var splitDescricao = descricao.split('<br>');
      return(
        `${splitDescricao[1]} | ${splitDescricao[3]} | ${splitDescricao[5]} | ${splitDescricao[6]}`
      );
    },
  },
};
</script>

<style scoped>
input:focus {
  /*border-color: #28a745 !important;*/
  box-shadow: none !important;
}

.formBordas{
  border: solid 1px rgba(0, 0, 0, 0.500);
  border-radius: 0.25em 0 0 0.25em;
  padding: 0px;
  margin-bottom: 20px;
}

</style>
